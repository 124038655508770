
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useInvocationStatus from "@/core/services/compositions/enum/useInvocationStatus";
import useInvocationType from "@/core/services/compositions/enum/useInvocationType";
import useChannel from "@/core/services/compositions/enum/useChannel";
import useGateway from "@/core/services/compositions/enum/useGateway";
import { useStore } from "vuex";
import { checkRole } from '@/router/authentication'
import usePaymentTerminal from "@/core/services/compositions/enum/usePaymentTerminal";

interface Filter {
  ref_id: string;
  type: Array<string>;
  institution: string;
  va_number: string;
  bcn: string;
  channel: string;
  gateway: string;
  elapsed_time: string;
  condition: string;
  payment_terminal: string;
  status: Array<string>;
  date: Array<string>;
}

export default defineComponent({
  name: "filter-invocation",
  components: {},
  setup(props, context) {
    const { invocationStatus } = useInvocationStatus();
    const { paymentTerminal } = usePaymentTerminal();
    const { invocationType } = useInvocationType();
    const { channel } = useChannel();
    const { gateway } = useGateway();
    const { query } = useQuery();

    const data = reactive<Filter>({
      ref_id: query.value.ref_id || '',
      type: typeof query.value.type == 'string' ? [query.value.type] : typeof query.value.type == 'object' ? query.value.type : '',
      institution: query.value.institution || '',
      va_number: query.value.va_number || '',
      bcn: query.value.bcn || '',
      channel: query.value.channel || '',
      gateway: query.value.gateway || '',
      elapsed_time: query.value.elapsed_time || '',
      condition: query.value.condition || '',
      payment_terminal: query.value.payment_terminal || '',
      status: typeof query.value.status == 'string' ? [query.value.status] : typeof query.value.status == 'object' ? query.value.status : '',
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.ref_id = '';
      data.va_number = '';
      data.bcn = '';
      data.type = [];
      data.institution = '';
      data.channel = '';
      data.gateway = '';
      data.elapsed_time = '';
      data.condition = '';
      data.payment_terminal = '';
      data.status = [];
      data.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    };

    return {
      data,
      filter,
      reset,
      invocationStatus,
      invocationType,
      paymentTerminal,
      channel,
      gateway,
      checkRole
    };
  },
  computed: {
    userData() {
      return useStore().getters.currentUser;
    }
  }
});
